import React from "react"
import WebsiteLayout from "../../components/layout/website/website-layout/website-layout"
import SEO from "../../services/seo"
import { useStaticQuery, graphql } from "gatsby"
import _ from "lodash"
import moment from "moment"
import styles from "./index.module.scss"
import DirectLink from "../../components/common/direct-link/direct-link"
import { MdAssignment } from "react-icons/md"
import util from "../../services/util"

// const host = process.env.GATSBY_FEDIA_DMS_HOST || "localhost"
// const port = process.env.GATSBY_FEDIA_DMS_PORT || 1337

// const baseUrl = process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
//   `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
//   `http://${process.env.GATSBY_FEDIA_DMS_HOST}/`;

const ordinal_suffix_of = i => {
  var j = i % 10,
    k = i % 100
  if (j == 1 && k != 11) {
    return "st"
  }
  if (j == 2 && k != 12) {
    return "nd"
  }
  if (j == 3 && k != 13) {
    return "rd"
  }
  return "th"
}

const MeetingsPage = () => {
  const data = useStaticQuery(graphql`
    query PageMeetingsQuery {
      allStrapiMeetings {
        nodes {
          meetings {
            id
            minutes {
              source_file
              title
            }
            photos
            date
            type
            title
            meetingNum
            location
          }
        }
      }
    }
  `)
  const minutes = _.orderBy(
    data.allStrapiMeetings.nodes[0].meetings,
    ["meetingNum", "type"],
    ["desc", "desc"]
  )
  console.log("minutes:", minutes)
  return (
    <WebsiteLayout>
      <SEO title="Meetings" />
      <div className={styles.wrapper}>
        <h1>Meetings</h1>
        {minutes.slice(0, 1).map(m => (
          <div className={styles.description}>
            <h5>
              The {m.meetingNum}
              {ordinal_suffix_of(m.meetingNum)} Annual General Meeting was held
              on {moment(m.date, "YYYY-MM-DD").format("dddd, MMMM Do YYYY")}, in{" "}
              {m.location}, Alberta.
            </h5>
            <p>
              Thank you to all the guest speakers, attendees and organizers for
              a fantastic evening!
            </p>
          </div>
        ))}
        <ul className={styles.meetings}>
          {minutes.map(m => (
            <li key={m.id} className={styles.meeting}>
              {m.meetingNum !== 0 ? (
                <div className={styles.meetingNumber}>
                  <span>{m.meetingNum}</span>
                  {ordinal_suffix_of(m.meetingNum)}
                </div>
              ) : (
                <div className={styles.meetingIcon}>
                  <MdAssignment />
                </div>
              )}

              <div className={styles.thumbnail}>
                <span></span>
              </div>
              <div className={styles.info}>
                <h3 className={styles.title}>{m.title}</h3>
                <div className={styles.subtitle}>
                  <p>
                    Location: <span>{m.location}</span>
                  </p>
                  {/* {m.photos.length > 0 ? (
                    <div className={styles.meetingPhotosWrap}>
                      Photos:
                      <ul className={styles.meetingPhotos}>
                        {m.photos.map(p => (
                          <li key={p.id}>
                            <a
                              href={
                                "http://" +
                                host +
                                ":" +
                                port +
                                "/download/" +
                                p.source_file +
                                "/" +
                                p.filename +
                                ".pdf"
                              }
                            >
                              {p.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
                <DirectLink
                  linkTo={`${util.baseUrl()}download/${m.minutes.source_file}/${m.minutes.title}.pdf`}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </WebsiteLayout>
  )
}

export default MeetingsPage

import React from "react"
import PropTypes from "prop-types"
import styles from "./direct-link.module.scss"
import { MdKeyboardArrowRight } from "react-icons/md"
import classNames from "classnames"
import { Link } from "gatsby"

const DirectLink = ({ linkTo, to, text, className }) => {
  if (to) {
    return (
      <Link to={to} className={classNames(styles.directLink, className)}>
        <span>
          <MdKeyboardArrowRight />
        </span>
        {text}
      </Link>
    )
  } else {
    return (
      <a href={linkTo} className={classNames(styles.directLink, className)}>
        <span>
          <MdKeyboardArrowRight />
        </span>
        {text}
      </a>
    )
  }
}

DirectLink.defaultProps = {
  linkTo: "/",
  text: "Read and Download",
}

DirectLink.propTypes = {
  linkTo: PropTypes.string,
  to: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
}

export default DirectLink
